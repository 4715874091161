import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/object-fit/ls.object-fit";
import Alpine from 'alpinejs';
// import Swiper JS
import Swiper, { Navigation } from "swiper";
// import Swiper styles
import "swiper/swiper-bundle.css";
import HeadroomJS from "./components/ui/Headroom";
import Mmenu from "mmenu-js";
import "mmenu-js/dist/mmenu.css";

window.Alpine = Alpine;

Alpine.start();

Swiper.use([Navigation]);

const swiper = new Swiper(".swiper-container", {
    // Default parameters
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    allowTouchMove: false,
    centeredSlides: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    // Responsive breakpoints
    breakpoints: {
        // when window width is >= 320px

    },
    on: {
        init: function () {
            console.log("swiper initialized");
        },
    },
});

document.addEventListener("lazybeforeunveil", function (e) {
    var bg = e.target.getAttribute("data-bg");
    if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")";
    }
});

document.addEventListener("DOMContentLoaded", () => {
    new Mmenu("#mobile-menu", {
        extensions: ["pagedim-black"],
        counters: true,
        navbars: [
            {
                position: "top",
                content: ["searchfield"],
            },
        ],
    });
});

// import HeadroomJS from './components/ui/Headroom'
import VenveoApplication from "./base/VenveoApplication";

const application = new VenveoApplication();

application.registerComponent("headroomjs", "header", HeadroomJS);
application.registerComponent("glightbox", ".glightbox", "ui/Glightbox");

// application.registerComponent('brand-slider', '[data-brand-slider]', 'ui/sliders/BrandSlider')
// application.registerComponent('testimonial-slider', '[data-testimonial-slider]', 'ui/sliders/TestimonialSlider')
// application.registerComponent('card-slider', '[data-card-slider]', 'ui/sliders/CardSlider')

application.init().then(() => {
    console.log("Site is ready.");
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
